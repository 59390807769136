import React, { useEffect } from 'react';
import styled from 'styled-components';
import PageWrapper from 'components/PageWrapper';
import ModularBlocks from 'components/ModularBlocks';
import SEO from 'components/SEO';
import { dataLayerPush } from 'utils/dataLayer';
import mapRoute from 'utils/linkMapper';

const LiveStreamingPageWrapper = styled(PageWrapper)`
  .modular-block-textBlock, .modular-block-episodeFeed {
    ${props => props.theme.breakpoints.up('lg')} {
      padding: 3rem 1rem;
    }

    ${props => props.theme.breakpoints.up('xl')} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .MuiContainer-maxWidthXl {
    max-width: none !important;
    padding: 5rem 5rem 2rem;

    ${props => props.theme.breakpoints.down('md')} {
      padding: 1rem 1rem;
    }
  }
  
  .modular-block-episodeFeed {
    ${props => props.theme.breakpoints.down('lg')} {
      padding: 0 5rem 5rem;
    }

    ${props => props.theme.breakpoints.down('md')} {
      padding: 1rem 1rem;
    }
      
    @media (min-width: 1440px) {
      padding: 0 5rem 5rem;
    }
  }

   .modular-block-textBlock h1 {
     margin-bottom: 0;
   }
`;

function LiveStreamPage({ page }) {
  const { pageBody, seo, title } = page;

  useEffect(() => {
    // Ensure window is there (will return undefined during initial render
    // with SSR); also checking for the dataLayer length will ensure there
    // is not a double push of page type on initial load / render
    if (typeof window !== 'undefined' && window?.dataLayer?.length > 0) {
      dataLayerPush(mapRoute('/live-stream'), 'page_type');
    }
  }, [])

  return (
    <LiveStreamingPageWrapper noTopMargin>
      <SEO seo={seo} title={title} />
      <ModularBlocks modularBlocks={pageBody} page='live-stream' />
    </LiveStreamingPageWrapper>
  );
}

export default React.memo(LiveStreamPage);